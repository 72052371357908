export default {
  primary: '#1b1818',
  disabled: '#E0E0E0',
  white: '#fff',
  blue: '#2F80ED',
  purple: '#9B51E0',
  green: '#27AE60',
  red: '#EB5757',
  dark: '#333',
  grey: '#828282',
  grey2: '#4F4F4F',
  darkGrey: '#999',
  lightGrey: '#bdbdbd',
  lighterGrey: 'rgba(189, 189, 189, 0.32)',
  yellow: '#F2C94C',
  lightBlue: '#56CCF2',
  lighterBlue: 'rgba(86, 204, 242, 0.32)',
  black: '#000',
}
