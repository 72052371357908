import AdministrativeIcon from 'icons/administrative'
import DataIcon from 'icons/dataIcon'
import FinanceiroIcon from 'icons/financeiro'
import MoneyIcon from 'icons/money'
import ProductIcon from 'icons/product'
import SettingsIcon from 'icons/settings'
import { IconType } from 'icons/types'

type MenuOptions = Array<{
  id: number
  title: string
  route?: string
  query?: string
  icon: IconType
  collapseOptions: Array<{
    title: string
    route: string
    query?: string
  }>
}>

export const menuOptions: MenuOptions = [
  {
    id: 1,
    title: 'Administrativo',
    route: '/administrativo',
    icon: AdministrativeIcon,
    collapseOptions: [
      {
        title: 'Lojas',
        route: '/lojas/listar',
      },
      {
        title: 'Bancos',
        route: '/bancos/listar',
      },
      {
        title: 'Fornecedores',
        route: '/fornecedores/listar',
      }
    ],
  },
  {
    id: 2,
    title: 'Financeiro',
    route: '/financeiro',
    icon: FinanceiroIcon,
    collapseOptions: [          
      {
        title: 'Contas a Pagar',
        route: '/contas-a-pagar/listar',
      },
      {
        title: 'Contas a Receber',
        route: '/contas-a-receber/listar',
      }
    ],
  },
  {
    id: 3,
    title: 'Produtos',
    route: '/produtos',
    icon: ProductIcon,
    collapseOptions: [
      {
        title: 'Grupo de Produtos',
        route: '/grupo-produtos/listar',
      },
      {
        title: 'Produtos',
        route: '/produtos/listar',
      },
      {
        title: 'Produtos Especiais',
        route: '/produtos-especiais/listar',
      },
      {
        title: 'Entrada de Produtos',
        route: '/entrada-produto/listar',
      },
      {
        title: 'Promoção de Produtos',
        route: '/promocao-produto/listar',
      },
      {
        title: 'Combos',
        route: '/combos/listar',
      },
    ],
  },  
  {
    id: 4,
    title: 'Vendas',
    route: '/vendas',
    icon: MoneyIcon,
    collapseOptions: [
      {
        title: 'Clientes',
        route: '/clientes/listar',        
      },   
      {
        title: 'Venda',
        route: '/venda/cadastrar',        
      },   
    ],
  },
  {
    id: 5,
    title: 'Relatórios gerenciais',
    route: undefined,
    icon: DataIcon,
    collapseOptions: [],
  },
  {
    id: 6,
    title: 'Configurações do sistema',
    route: '/configuracao-sistema',
    icon: SettingsIcon,
    collapseOptions: [
      {
        title: 'Usuários',
        route: '/usuario/listar',        
      },
      {
        title: 'Permissões',
        route: '/permissoes/listar',        
      },
      {
        title: 'Configurações',
        route: '/configuracao-sistema/listar',        
      },

    ],
  },  
]

type MenuCollapseOptions = MenuOptions[0]['collapseOptions']

type GetMenuOptionsProps = Array<{
  route: string
  hasPermission: boolean
}>

export function getMenuOptions(list: GetMenuOptionsProps) {
  const result = menuOptions.reduce((acc: MenuOptions, current) => {    
    if (current.route) {
      const hasPermission = list.some(permission => permission.route === current.route && permission.hasPermission)      
      if (!hasPermission) return acc
      return [...acc, current]
    }

    if (current.title === 'Consulta') { // TODO: Verificar alternativa para remover esta validação
      return [...acc, current]
    }

    const collapseOptions: MenuCollapseOptions = []
    current.collapseOptions.forEach(option => {
      const hasPermission = list.some(permission => permission.route === option.route && permission.hasPermission)
      if (hasPermission) {
        collapseOptions.push(option)
      }
    })

    if (collapseOptions.length > 0) {
      return [...acc, { ...current, collapseOptions }]
    }

    return acc
  }, [])

  return result
}
