import { call, put, SagaReturnType, takeLatest } from 'redux-saga/effects'

import { AxiosError } from 'axios'
import { getLojasVendendor, login as loginService,getLojaVendendorSelecionada } from 'services/login.services'
import { errorActions } from 'store/errors/duck'
import { applyLoading } from 'store/loader/applyLoading'
import { lojaActions } from 'store/loja/duck'
import { permissionActions } from 'store/permissions/duck'

import { LoginPayload, userActions } from './duck'


type GetLojasVendendorByLoginResult = SagaReturnType<typeof getLojasVendendor>
type SelecionarResult = SagaReturnType<typeof getLojaVendendorSelecionada>
type LoginResult = SagaReturnType<typeof loginService>

function* userLogin({ payload: { email, password, rememberLogin, onSuccess, onError } }: LoginPayload) {
  try {
    yield put(errorActions.reset())
    yield put(userActions.setLoading(true))
    const response: LoginResult = yield call(loginService, { email, password })
    
    yield put(userActions.setLoading(false))

    if (!response.success) {
      throw new Error(response.errorMessage)
    }   
    
    yield put(permissionActions.load(response.data.cod))

    yield put(userActions.setToken(response.data.accessToken))

    yield put(userActions.set({ ...response.data, rememberLogin, loading: false }))    

    const cod: string = response.data.cod

    const responseListaLojas: GetLojasVendendorByLoginResult = yield call(getLojasVendendor, { cod })
    
    if (!responseListaLojas.success) {
      throw new Error(responseListaLojas.errorMessage)
    }

    yield put(lojaActions.set(responseListaLojas.data.map(l => ({
      idLoja: l.idLoja,
      nomeLoja: l.nomeLoja,
      favorita: l.favorita,
      especialLoja: l.especialLoja,
      cnpj: l.cnpj
    }))));

    const responseLojaVendendor : SelecionarResult = yield call(getLojaVendendorSelecionada, {
      cod: response.data.cod
    })

    if(!responseLojaVendendor.success)  {
      throw new Error(responseLojaVendendor.errorMessage)
    }    

    yield put(lojaActions.setSelecionado(responseLojaVendendor.data.idLojas))    

    if (onSuccess) yield call(onSuccess)
  } catch (error) {
    yield put(userActions.setLoading(false))

    const dataError = (error as AxiosError).response?.data

    if (dataError.errors) {
      const arrayErros = dataError.errors as Array<string>
      if (arrayErros.includes('Usuário não encontrado!')) {
        yield put(errorActions.add({ key: 'dsLogin', errors: ['Usuário não encontrado!'] }))
      }

      if (arrayErros.includes('Digite uma senha válida!'))
        yield put(errorActions.add({ key: 'dsSenha', errors: ['Digite uma senha válida!'] }))
    }
    if (onError) yield call(onError)
  }
}

export default function* userSaga(): Generator {
  yield takeLatest(userActions.login.type, applyLoading(userLogin))
}
