import { createSlice } from '@reduxjs/toolkit';
import { Usuario } from "modules/usuarios/types"

type UsuarioStore = {
    currentStep: number
    completedStep: Array<number>
    isSubmiting: boolean
    origin?: string
    values: Usuario
}

const initialState: UsuarioStore ={
    currentStep: 0,
    completedStep: [],
    isSubmiting: false,
    values:{
        id: undefined,
        nome: '',
        email: '',
        urlFotoUsuario: undefined,
        ativo: 'False',
        cpf: '',
        vendedor: 'False',
        avatar: undefined
    }
}

export const usuarioSlice = createSlice({
    name: 'usuario',
    initialState,
    reducers: {        
        create: state => state,
        update: state => state,
        reset: () => initialState,
        setIsSubmiting(state, action) {
            state.isSubmiting = action.payload
        },
        setOrigin(state, action) {
            state.origin = action.payload
        },
        setValues(state, action) {
            state.values = {
              ...state.values,
              ...action.payload,
            }
        },
        setCurrentStep(state, action) {
            state.currentStep = action.payload
        },
        setCompletedStep(state, action) {
            state.completedStep = action.payload
        },
    }
})

export const usuarioActions = usuarioSlice.actions
export default usuarioSlice.reducer