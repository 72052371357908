import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl();

export const endpoints = {
  cadastrar: BASE_URL,
  consultarPF: `${BASE_URL}/PorFiltro`,
  consultarPfPorCpf: `${BASE_URL}/PorCpf`,
  consultarPfPorRg: `${BASE_URL}/PorRg`,
  consultarPfPorOutroDoc: `${BASE_URL}/PorOutroDocumento`,
  atualizar(idPessoaFisica: string) {
    return `${BASE_URL}/${idPessoaFisica}`
  },
  cpfCadastrado(cpf: string) {
    return `${BASE_URL}/CpfCadastrado/${cpf}`
  },
  validarDocumento(tpDocumento: string, nuDocumento: string) {
    return `${BASE_URL}/ValidarNumeroDocumento/${tpDocumento}/${nuDocumento}`
  },
  obterPessoaFisicaPorId(idPessoaFisica: string) {
    return `${BASE_URL}/${idPessoaFisica}`
  },
  excluirPessoaFisica(idPessoaFisica: string) {
    return `${BASE_URL}/${idPessoaFisica}`
  },
}
