import React, {
  createContext, lazy, useContext, useMemo 
} from 'react'
import { useLocation } from 'react-router-dom'

import find from 'lodash/find'
import map from 'lodash/map'

import GuestScreenRouter from './ScreenRouter/GuestScreenRouter'
import { RouteType } from './ScreenRouter/RouterSwitch'

const LoginScreen = lazy(() => import('modules/auth/screens/LoginScreen'))
const ForgotPassWordScreen = lazy(() => import('modules/auth/screens/ForgotPassWordScreen'))
const ConfirmarEmailScreen = lazy(() => import( 'modules/auth/screens/ConfirmarEmailScreen'))
const ErroConfirmarEmailScreen = lazy(() => import('modules/auth/screens/ErroConfirmarEmailScreen'))


interface Routes {
  currentPath: string
  currentRoute?: RouteType
  routes: Record<string, RouteType>
}

const RoutesContext = createContext<Routes>({ currentPath: '', routes: {} })

export const useRoutes = () => useContext(RoutesContext)

const GuestRoutesProvider: React.FC = () => {
  const { pathname: currentPath } = useLocation()

  const routes = useMemo(
    () => ({
      login: {
        path: '/',
        Component: LoginScreen,
      },
      forgotPassWord: {
        path: '/forgot-password',
        Component: ForgotPassWordScreen,
      },
      confirmarEmail: {
        path: '/confirmar-email/:cod/:user',
        Component: ConfirmarEmailScreen,
      },
      erroConfirmarEmail: {
        path: '/erro-confirmar-email',
        Component: ErroConfirmarEmailScreen,
      }
    }),
    []
  )

  const currentRoute: RouteType | undefined = useMemo(
    () =>
      find(
        map(routes, item => item),
        { path: currentPath }
      ),
    [routes, currentPath]
  )

  const toolkit = useMemo(
    () => ({
      currentPath,
      currentRoute,
      routes,
    }),
    [currentPath, currentRoute, routes]
  )

  return (
    <RoutesContext.Provider value={toolkit}>
      <GuestScreenRouter />
    </RoutesContext.Provider>
  )
}

export default GuestRoutesProvider
