import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, To, NavigateOptions, useLocation } from 'react-router-dom'

import { navigationActions } from 'store/actions'
import { ResetFormOptions } from 'store/navigation/slice'
import { navigationSelectors } from 'store/selectors'
import { generateRandomId } from 'utils/helpers'

import useModal from './useModal'

type HandleNavigateOptions = NavigateOptions & {
  resetForm?: ResetFormOptions
  noConfirmation?: boolean
  confirmationMsg?: string
}

export const useHandleNavigate = () => {
  const [key] = useState<number>(generateRandomId())
  const [destination, setDestination] = useState<To | number>('')
  const [navigationOptions, setNavigationOptions] = useState<HandleNavigateOptions>()

  const modalConfirm = useModal('modal-confirm-data-loss')

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const navigationConfirm = useSelector(navigationSelectors.navigationConfirm)
  const navigationCancel = useSelector(navigationSelectors.navigationCancel)
  const navigationKey = useSelector(navigationSelectors.navigationKey)

  const go = useCallback(
    (to: To | number, options?: HandleNavigateOptions) => {
      if (typeof to === 'number') {
        navigate(to)
      } else {
        navigate(to, options)
      }
    },
    [navigate]
  )

  const handleNavigate = useCallback(
    (to: To | number, options?: HandleNavigateOptions) => {
  
      if (to === location.pathname) {
        return
      }
      
      if (options && options.noConfirmation) {
        go(to, options)
        return
      }

      if (
        location.pathname.includes('cadastrar') ||
        location.pathname.includes('editar') ||
        location.search.includes('origin')
      ) {        
        setDestination(to)
        setNavigationOptions(options)
        dispatch(navigationActions.setNavigationKey(key))
        dispatch(navigationActions.setConfirmationMsg(options?.confirmationMsg))
        modalConfirm.openModal()
      } else {
        go(to, options)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, location.pathname, modalConfirm]
  )

  useEffect(() => {
    if (key === navigationKey && navigationConfirm) {
      dispatch(navigationActions.resetNavigationState())
      dispatch(navigationActions.resetFormState(navigationOptions?.resetForm || 'ALL'))
      modalConfirm.closeModal()
      go(destination, navigationOptions)
    }

    if (key === navigationKey && navigationCancel) {
      dispatch(navigationActions.resetNavigationState())
      modalConfirm.closeModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationCancel, navigationConfirm, destination, navigationOptions])

  return handleNavigate
}
