import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl()

export const endpoints = {
  create: `${BASE_URL}/ProcProcedimentoFato`,
  update(dadosDoFatoId: string) {
    return `${BASE_URL}/ProcProcedimentoFato/${dadosDoFatoId}`
  },
  delete(dadosDoFatoId: string) {
    return `${BASE_URL}/ProcProcedimentoFato/${dadosDoFatoId}`
  },
  getByProcessoProcedimentoId(processoProcedimentoId: string) {
    return `${BASE_URL}/ProcProcedimentoFato/${processoProcedimentoId}`
  },
  uploadAnexo: `${BASE_URL}/AnexoFato/upload`,
  deleteAnexo(anexoId: string) {
    return `${BASE_URL}/AnexoFato/${anexoId}`
  },
  downloadAnexo(anexoId: string) {
    return `${BASE_URL}/AnexoFato/Download/${anexoId}`
  },
  getAnexoById(anexoId: string) {
    return `${BASE_URL}/AnexoFato/ObterPorId/${anexoId}`
  },
  getAnexoByDadosDoFatoId(dadosDoFatoId: string) {
    return `${BASE_URL}/AnexoFato/${dadosDoFatoId}`
  },
  getListTipoLocal: `${BASE_URL}/TpLocal`,
  getListUnidadePolicial: `${BASE_URL}/UnidadePolicial`,
}
