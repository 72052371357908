import { useNavigate } from 'react-router-dom'

import { Button } from 'components/base/buttons'
import Modal from 'components/modal'
import useModal from 'hooks/useModal'

import { Typography } from '@material-ui/core'

export const ModalNoPermission = () => {
  const navigate = useNavigate()
  const modal = useModal('modal-no-permission')

  const handleClose = () => {
    modal.closeModal()
    navigate('/')
  }

  if (!modal.isOpen) {
    return null
  }

  return (
    <Modal modalId={'modal-no-permission'} fixed>
      <Modal.Header>Atenção!</Modal.Header>
      <Modal.Body>
        <Typography align="center">
          Você não possui permissão de acesso a esta funcionalidade. Por favor, entre em contato com o administrador
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <Button.Contained onClick={handleClose} color="warning">
          Ok, Obrigado
        </Button.Contained>
      </Modal.Footer>
    </Modal>
  )
}
