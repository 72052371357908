import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl()

export default {
  login: `${BASE_URL}/v1/login`,
  getPermissions: `${BASE_URL}/v1/permissoes`,
  getLojasVendendor: `${BASE_URL}/v1/lojas/lista-vendendor`,
  getLojaVendendorSelecionada: `${BASE_URL}/v1/lojas/selecionada`,
  selecionarLojaVendendor: `${BASE_URL}/v1/lojas/selecionar`,
  favoritarLoja: `${BASE_URL}/v1/lojas/favoritar`,
  
  getOrgaoUnidade: `${BASE_URL}/OrgaoUnidade/ObterCentrosCustoEDesignacoesPorLogin`,
  getDesignacoesPorFiltro: `${BASE_URL}/OrgaoUnidade/ObterDesignacoesPorFiltro`,
}
