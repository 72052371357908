import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
  cod: string
  email: string
  accessToken: string
  refreshToken:string
  expiresIn:number
  nome: string
  vendendor:boolean
  rememberLogin: boolean  
  loading: boolean 
  avatar:string
}

export const initialState: UserState = {
  cod: '',
  email: '',
  rememberLogin: false,
  accessToken: '',
  refreshToken:'',
  expiresIn:0,
  nome: '',
  vendendor:true,    
  avatar:'',
  loading: false,  
}

export type LoginPayload = PayloadAction<{
  email: string
  password: string
  rememberLogin: boolean
  onSuccess?: () => void
  onError?: () => void
}>

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state: UserState, { payload }: PayloadAction<UserState>) => ({ ...state, ...payload }),
    login: (state: UserState, { payload: _payload }: LoginPayload) => state,
    logoff(state: UserState) {
      if (!state.rememberLogin) {
        return initialState
      }
      return { ...initialState, email: state.email, rememberLogin: state.rememberLogin }
    },
    reset: () => initialState,
    setLoading: (state: UserState, { payload }: PayloadAction<boolean>) => ({ ...state, loading: payload }),
    setToken: (state: UserState, { payload }: PayloadAction<string>) => ({ ...state, dsToken: payload })
  },
})

export const userActions = userSlice.actions

export default userSlice.reducer
