import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Permission = {  
  urlTela: string
  nomeTela: string
  nomeAcao: string
  ativo: boolean
}

const initialState: Permission[] = []

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    set: (state: Permission[], { payload }: PayloadAction<Permission[]>) => payload,
    load: (state: Permission[], { payload: _payload }: PayloadAction<string>) => state,
    reset: () => initialState,
  },
})

export const permissionActions = permissionSlice.actions

export default permissionSlice.reducer
