import { createSelector } from 'reselect'
import { AppState } from 'store/rootReducer'

const dsLoginState = (state: AppState) => state.user.email
const tokenState = (state: AppState) => state.user.accessToken
const nameState = (state: AppState) => state.user.nome
const idUserState = (state: AppState) => state.user.cod

const rememberLoginUserState = (state: AppState) => state.user.rememberLogin
const loadingLoginUserState = (state: AppState) => state.user.loading

const userState = (state: AppState) => state.user

const username = createSelector(dsLoginState, login => login)

const token = createSelector(tokenState, dsToken => dsToken)

const name = createSelector(nameState, name => name)

const id = createSelector(idUserState, idUser => idUser)

const isAuthenticate = createSelector(tokenState, dsToken => Boolean(dsToken))

const rememberLogin = createSelector(rememberLoginUserState, rememberLogin => rememberLogin)

const loadingLogin = createSelector(loadingLoginUserState, loading => loading)

const user = createSelector(userState, userData => userData)

export default { username, token, name, id, isAuthenticate,  rememberLogin, loadingLogin, user }
