import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const state = (state: AppState) => state.usuario

const values = createSelector(state, usuario => usuario.values)
const currentStep = createSelector(state, usuario => usuario.currentStep)
const completedStep = createSelector(state, usuario => usuario.completedStep)
const origin = createSelector(state, usuario => usuario.origin)

export const usuarioSelectors = {
    completedStep,
    currentStep,    
    values,
    origin
  }