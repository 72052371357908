import { SagaReturnType, call, put, select, takeLatest } from 'redux-saga/effects'

import { PayloadAction } from '@reduxjs/toolkit'
import { favoritarLoja, getLojasVendendor, getLojaVendendorSelecionada,selecionarLojaVendendor } from 'services/login.services'
import { errorActions } from 'store/errors/duck'
import UserSelector from 'store/user/selector'

import { lojaActions } from './duck'

type GetLojasVendendorByLoginResult = SagaReturnType<typeof getLojasVendendor>
type GetSelecionadoResult = SagaReturnType<typeof getLojaVendendorSelecionada>
type GetSelecionarResult = SagaReturnType<typeof selecionarLojaVendendor>

function* favorite({ payload }: PayloadAction<{ idLoja: string; cod:string; favoritar: boolean }>) {
  try {    
    yield call(favoritarLoja, {
      idLoja:payload.idLoja,
      cod: payload.cod,
      favoritar: payload.favoritar,
    })
  } catch (error) {
    yield put(
      errorActions.add({
        key: lojaActions.favorite.type,
        errors: ['Não foi possível favoritar a Loja.'],
      })
    )
  }
}

function* updateSelecionado({ payload }: PayloadAction<{ idLoja: string; cod: string; }>) {
  try{    

    const response : GetSelecionarResult = yield call(selecionarLojaVendendor, {
      idLoja: payload.idLoja,
      cod: payload.cod
    })
    if(!response.success){
      throw new Error(response.errorMessage)
    }    

    yield put(lojaActions.setSelecionado(response.data.idLojas))
    yield put(lojaActions.load())
    
  }
  catch(error){
    yield put(
      errorActions.add({
        key: lojaActions.updateSelecionado.type,
        errors: ['Não foi possível selecionar a Loja.'],
    }))
  }
}

function* load() {
  try {
    const cod: string = yield select(UserSelector.id)    

    const response: GetLojasVendendorByLoginResult = yield call(getLojasVendendor, { cod })
    if (!response.success) {
      throw new Error(response.errorMessage)
    }

    const responseLojaVendendorSelecionada: GetSelecionadoResult = yield call(getLojaVendendorSelecionada,{cod})

    if (!responseLojaVendendorSelecionada.success) {
      throw new Error(responseLojaVendendorSelecionada.errorMessage)
    }    

    const lojaSelecionada = responseLojaVendendorSelecionada.data.idLojas   
  
    yield put(lojaActions.setSelecionado(lojaSelecionada))
    
  } catch (error) {
    yield put(
      errorActions.add({
        key: lojaActions.favorite.type,
        errors: ['Não foi possível favoritar o Órgão/Unidade.'],
      })
    )
  }
}

export default function* lojaSaga(): Generator {
  yield takeLatest(lojaActions.favorite.type, favorite)
  yield takeLatest(lojaActions.load.type, load)
  yield takeLatest(lojaActions.updateSelecionado.type, updateSelecionado)
}
