import { ReactElement } from 'react'

import colors from 'styles/colors'

import { IconProps } from './types'

const AdministrativeIcon = ({ size = 16, color = colors.grey }: IconProps): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 280 280" fill={color} xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M64.099,84.351c-1.278,0.488-2.076,1.789-1.941,3.163c1.753,17.819,6.123,37.356,12.323,56.136h66.927V55.027
        c-0.376,0-0.744,0.068-1.094,0.202L64.099,84.351z"/>
      <path d="M254.54,45.662l-101.104-43.2c-7.683-3.283-16.373-3.283-24.056,0l-101.104,43.2C15.904,50.949,8.44,63.719,9.882,77.095
        c9.517,88.316,69.731,205.722,131.526,205.722s122.01-117.406,131.526-205.722C274.378,63.703,266.926,50.955,254.54,45.662z
        M240.559,89.471c-6.656,67.673-48.406,162.8-99.152,162.8c-50.747,0-92.497-95.127-99.153-162.8
        c-1.017-10.337,5.003-20.095,14.706-23.803l76.215-29.122c2.65-1.013,5.442-1.519,8.232-1.519c2.791,0,5.582,0.506,8.233,1.519
        l76.213,29.122C235.571,69.381,241.578,79.118,240.559,89.471z"/>
      <path d="M141.746,232.259c14.741-0.256,32.736-17.255,48.22-45.599c7.068-12.938,13.322-27.724,18.367-43.011h-66.587V232.259z"/>
    </g>
  </svg>
)

export default AdministrativeIcon
