import { createSelector } from 'reselect'

import { AppState } from '../rootReducer'

const lojaState = (state: AppState) => state.loja.lojas

const lojaFiltrada = (state:AppState) => {
  
  if(state.loja.idLojaSelecionada)  {
    return state.loja.lojas.find(x => x.idLoja === state.loja.idLojaSelecionada)
  }
  else if(state.loja.lojas.find(s=>s.favorita)){
    return state.loja.lojas.find(x => x.favorita)
  }
  else{
    return state.loja.lojas[0]
  }  
}

const loja = createSelector(lojaState, loja => loja)
const idSelecionada = createSelector(lojaFiltrada, loja => loja?.idLoja)
const lojaSelecionada = createSelector(lojaFiltrada, loja => loja)


export const lojaSelectors = { loja, idSelecionada, lojaSelecionada  }
export default lojaSelectors
