import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import MenuDrawer from 'containers/MenuDrawer/MenuDrawer'
import Topbar from 'containers/topbar/Topbar'
import PermissionsSelector from 'store/permissions/selectors'

import { getMenuOptions } from './menu'
import { Content, Container, Main } from './styles'

const Authenticated: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false)

  const PERMISSION_ADMINISTRATIVO =  useSelector(PermissionsSelector.getPermissionsByUrl('/administrativo')).length > 0
  const PERMISSION_FIANCEIRO =  useSelector(PermissionsSelector.getPermissionsByUrl('/financeiro')).length > 0
  const PERMISSION_CONFIGURACAO_SISTEMA =  useSelector(PermissionsSelector.getPermissionsByUrl('/configuracao-sistema/listar')).length > 0

  const PERMISSION_LIST_PERMISSAO =  useSelector(PermissionsSelector.getPermissionsByUrl('/permissoes/listar')).length > 0
  const PERMISSION_LIST_USUARIO =  useSelector(PermissionsSelector.getPermissionsByUrl('/usuario/listar')).length > 0
  const PERMISSION_LIST_BANCOS =  useSelector(PermissionsSelector.getPermissionsByUrl('/bancos/listar')).length > 0
  const PERMISSION_LIST_CLIENTES =  useSelector(PermissionsSelector.getPermissionsByUrl('/clientes/listar')).length > 0
  const PERMISSION_LIST_COMBOS =  useSelector(PermissionsSelector.getPermissionsByUrl('/combos/listar')).length > 0  
  const PERMISSION_LIST_CONTAS_A_PAGAR =  useSelector(PermissionsSelector.getPermissionsByUrl('/contas-a-pagar/listar')).length > 0
  const PERMISSION_LIST_CONTAS_A_RECEBER =  useSelector(PermissionsSelector.getPermissionsByUrl('/contas-a-receber/listar')).length > 0
  const PERMISSION_LIST_ENTRADA_PRODUTO =  useSelector(PermissionsSelector.getPermissionsByUrl('/entrada-produto/listar')).length > 0
  const PERMISSION_LIST_FORNCEDORES =  useSelector(PermissionsSelector.getPermissionsByUrl('/fornecedores/listar')).length > 0
  const PERMISSION_LIST_GRUPO_PRODUTOS =  useSelector(PermissionsSelector.getPermissionsByUrl('/grupo-produtos/listar')).length > 0
  const PERMISSION_LIST_LOJAS =  useSelector(PermissionsSelector.getPermissionsByUrl('/lojas/listar')).length > 0
  const PERMISSION_LIST_PRODUTOS =  useSelector(PermissionsSelector.getPermissionsByUrl('/produtos/listar')).length > 0
  const PERMISSION_LIST_PRODUTOS_ESPECIAIS =  useSelector(PermissionsSelector.getPermissionsByUrl('/produtos-especiais/listar')).length > 0
  const PERMISSION_LIST_PROMOCAO_PRODUTO =  useSelector(PermissionsSelector.getPermissionsByUrl('/promocao-produto/listar')).length > 0

  const PERMISSION_CAD_VENDA =  useSelector(PermissionsSelector.getPermissionsByUrl('/venda/cadastrar')).length > 0


  const mappedPermissions = [
    { route: '/administrativo', hasPermission: PERMISSION_ADMINISTRATIVO },
    { route: '/financeiro', hasPermission: PERMISSION_FIANCEIRO },
    { route: '/usuario/listar', hasPermission: PERMISSION_LIST_USUARIO },
    { route: '/permissoes/listar', hasPermission: PERMISSION_LIST_PERMISSAO },
    { route: '/bancos/listar', hasPermission: PERMISSION_LIST_BANCOS },
    { route: '/clientes/listar', hasPermission: PERMISSION_LIST_CLIENTES },
    { route: '/produtos', hasPermission: true },
    { route: '/consulta', hasPermission: true },
    { route: '/vendas', hasPermission: true },
    { route: '/combos/listar', hasPermission: PERMISSION_LIST_COMBOS },
    { route: '/configuracao-sistema', hasPermission: PERMISSION_CONFIGURACAO_SISTEMA },
    { route: '/contas-a-pagar/listar', hasPermission: PERMISSION_LIST_CONTAS_A_PAGAR },
    { route: '/contas-a-receber/listar', hasPermission: PERMISSION_LIST_CONTAS_A_RECEBER },
    { route: '/entrada-produto/listar', hasPermission: PERMISSION_LIST_ENTRADA_PRODUTO },
    { route: '/fornecedores/listar', hasPermission: PERMISSION_LIST_FORNCEDORES },    
    { route: '/grupo-produtos/listar', hasPermission: PERMISSION_LIST_GRUPO_PRODUTOS },    
    { route: '/lojas/listar', hasPermission: PERMISSION_LIST_LOJAS },    
    { route: '/produtos/listar', hasPermission: PERMISSION_LIST_PRODUTOS },    
    { route: '/produtos-especiais/listar', hasPermission: PERMISSION_LIST_PRODUTOS_ESPECIAIS },    
    { route: '/promocao-produto/listar', hasPermission: PERMISSION_LIST_PROMOCAO_PRODUTO },    
    { route: '/venda/cadastrar', hasPermission: PERMISSION_CAD_VENDA },    
  ]

  const dataMenu = getMenuOptions(mappedPermissions)

  return (
    <Container>
      <Topbar id="topbar" onClick={() => setOpen(prev => !prev)} />
      <Main>
        <div className="main__wrapper">
          <MenuDrawer id="MenuDrawer" data={dataMenu} openDrawer={open} onClick={() => setOpen(prev => !prev)} />
          <Content>
            <div className="content__wrapper">{children}</div>
          </Content>
        </div>
      </Main>
    </Container>
  )
}

export default Authenticated
