import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl()

export const endpoints = {
  cadastrar: BASE_URL,
  consultarPJ: `${BASE_URL}/PorFiltro`,
  atualizar(idPessoaJuridica: string) {
    return `${BASE_URL}/${idPessoaJuridica}`
  },
  obterPjPorCnpj(nuCnpj: string) {
    return `${BASE_URL}/PorCnpj?nuCnpj=${nuCnpj}`
  },
  obterPessoaJuridicaPorId(idPessoaJuridica: string) {
    return `${BASE_URL}/${idPessoaJuridica}`
  },
  deletePessoaJuridica(idPessoaJuridica: string) {
    return `${BASE_URL}/${idPessoaJuridica}`
  },
  validarPessoaFisicaERepresentante(idPessoaFisica: number) {
    return `${BASE_URL}/PessoaFisicaERepresentante/${idPessoaFisica}`
  },
}
