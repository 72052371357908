import { getBaseUrl } from '../get-base-url'

const BASE_URL = getBaseUrl()

export const endpoints = {
  getProcessosProcedimentos: `${BASE_URL}/ProcProcedimento/PorFiltro`,
  cadastrar: `${BASE_URL}/ProcProcedimento`,
  cadastrarInstrumento: `${BASE_URL}/ProcProcedimentoInstrumento`,
  tipoExpediente: `${BASE_URL}/TpExpediente`,
  tipoEnvolvimento: `${BASE_URL}/TpEnvolvimento`,
  tipoInstrumento: `${BASE_URL}/TpInstrumento`,
  unidadeMedida: `${BASE_URL}/UnidadeMedida`,
  modoApreensao: `${BASE_URL}/ModoApreensao`,
  atualizar(idProcessoProcedimento: string) {
    return `${BASE_URL}/ProcProcedimento/${idProcessoProcedimento}`
  },
  obterProcessoProcedimentoPorId(idProcessoProcedimento: string) {
    return `${BASE_URL}/ProcProcedimento/${idProcessoProcedimento}`
  },
  obterInstrumentosPorProcessoProcedimento(idProcessoProcedimento: string) {
    return `${BASE_URL}/ProcProcedimentoInstrumento/${idProcessoProcedimento}`
  },
  excluirInstrumento(idInstrumento: string) {
    return `${BASE_URL}/ProcProcedimentoInstrumento/${idInstrumento}`
  },
  atualizarInstrumento(idInstrumento: string) {
    return `${BASE_URL}/ProcProcedimentoInstrumento/${idInstrumento}`
  },
  processoJudicialAssociado(nuProcesso: string) {
    return `${BASE_URL}/ProcProcedimento/ProcJudAssociadoProcProcedimento/${nuProcesso}/0`
  },
  validarNumeroProcessoJudicial(nuProcesso: string) {
    return `${BASE_URL}/ProcProcedimento/ValidarNumeroProcessoJudicialUnificado?nuProcesso=${nuProcesso}`
  },
  verificarExistenciaNuIdea(nuIdea: string) {
    return `${BASE_URL}/ProcProcedimento/VerificarExistenciaNuIdea?nuIdea=${nuIdea}`
  },
}

export const endpointsMovimento = {
  getMovimentoTipos: `${BASE_URL}/Movimento/Tipos`,
  createMovimento: `${BASE_URL}/Movimento`,
  uploadAnexoMovimento: `${BASE_URL}/Movimento/anexo/upload`,
}
