import { ReactElement } from 'react'

import colors from 'styles/colors'

import { IconProps } from './types'

const ProductIcon = ({ size = 19, color = colors.grey }: IconProps): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M13.5 2.75H20.5V1.25H13.5V2.75ZM21.25 3.5V7.5H22.75V3.5H21.25ZM20.5 8.25H13.5V9.75H20.5V8.25ZM12.75 7.5V3.5H11.25V7.5H12.75ZM13.5 8.25C13.0858 8.25 12.75 7.91421 12.75 7.5H11.25C11.25 8.74264 12.2574 9.75 13.5 9.75V8.25ZM21.25 7.5C21.25 7.91421 20.9142 8.25 20.5 8.25V9.75C21.7426 9.75 22.75 8.74264 22.75 7.5H21.25ZM20.5 2.75C20.9142 2.75 21.25 3.08579 21.25 3.5H22.75C22.75 2.25736 21.7426 1.25 20.5 1.25V2.75ZM13.5 1.25C12.2574 1.25 11.25 2.25736 11.25 3.5H12.75C12.75 3.08579 13.0858 2.75 13.5 2.75V1.25Z" fill={color}/>
  <path d="M2 13.25C1.58579 13.25 1.25 13.5858 1.25 14C1.25 14.4142 1.58579 14.75 2 14.75V13.25ZM22 14.75C22.4142 14.75 22.75 14.4142 22.75 14C22.75 13.5858 22.4142 13.25 22 13.25V14.75ZM2 14.75H22V13.25H2V14.75Z" fill={color}/>
  <path d="M2 17.25C1.58579 17.25 1.25 17.5858 1.25 18C1.25 18.4142 1.58579 18.75 2 18.75V17.25ZM22 18.75C22.4142 18.75 22.75 18.4142 22.75 18C22.75 17.5858 22.4142 17.25 22 17.25V18.75ZM2 18.75H22V17.25H2V18.75Z" fill={color}/>
  <path d="M2 21.25C1.58579 21.25 1.25 21.5858 1.25 22C1.25 22.4142 1.58579 22.75 2 22.75V21.25ZM14 22.75C14.4142 22.75 14.75 22.4142 14.75 22C14.75 21.5858 14.4142 21.25 14 21.25V22.75ZM2 22.75H14V21.25H2V22.75Z" fill={color}/>
  <path d="M9 6.25C9.41421 6.25 9.75 5.91421 9.75 5.5C9.75 5.08579 9.41421 4.75 9 4.75V6.25ZM2 4.75C1.58579 4.75 1.25 5.08579 1.25 5.5C1.25 5.91421 1.58579 6.25 2 6.25V4.75ZM9 4.75H2V6.25H9V4.75Z" fill={color}/>
  <path d="M6.56944 1.51191C6.29988 1.19741 5.8264 1.16099 5.51191 1.43056C5.19741 1.70012 5.16099 2.1736 5.43056 2.48809L6.56944 1.51191ZM9 5.5L9.56944 5.98809C9.81019 5.70723 9.81019 5.29277 9.56944 5.01191L9 5.5ZM5.43056 8.51191C5.16099 8.8264 5.19741 9.29988 5.51191 9.56944C5.8264 9.83901 6.29988 9.80259 6.56944 9.48809L5.43056 8.51191ZM5.43056 2.48809L8.43056 5.98809L9.56944 5.01191L6.56944 1.51191L5.43056 2.48809ZM8.43056 5.01191L5.43056 8.51191L6.56944 9.48809L9.56944 5.98809L8.43056 5.01191Z" fill={color}/>
  </svg>
)

export default ProductIcon
