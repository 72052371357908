import styled from 'styled-components'
import colors from 'styles/colors'

export const TopbarContainer = styled.div`
  flex: 0 0 auto;
  height: 60px;
`

export const TopbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: ${colors.primary};
  padding: 0 12px;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`

export const IconsDivider = styled.div`
  height: 16px;
  opacity: 0.4;
  border: 2px solid #f2f2f2;
  border-radius: 10px;
  margin: 0 20px;
`

export const TextDivider = styled.div`
  color: white;
`