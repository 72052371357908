import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Lojas ={
  idLoja: string
  nomeLoja:string
  favorita:boolean
  especialLoja: boolean
  cnpj:string
}

export type LojaState = {  
  lojas:Lojas[]
  idLojaSelecionada:string
}

export type Favorite = {
  idLoja: string  
}

const initialState: LojaState = { lojas: [],idLojaSelecionada:'' }

const lojaSlice = createSlice({
  name: 'loja',
  initialState,
  reducers: {        
    set: (state: LojaState, { payload }: PayloadAction<Lojas[]>) => ({ ...state, lojas: payload }),    
    load: (state: LojaState) => state,
    reset: () => initialState,
    favorite: (
      state: LojaState,
      { payload: _payload }: PayloadAction<{ idLoja: string; cod: string; favoritar: boolean }>
    ) => state,

    // faz update do LojaState no backend e depois 
    // atualiza o estado da aplicação (LojaState)
    updateSelecionado: (state: LojaState, { payload: _payload}: PayloadAction<{ idLoja: string; cod: string; }>) => ({...state, idLoja: _payload}),

    // apenas autaliza o LojaState no estado da aplicação. Utilizado por métodos que
    // antes buscam a informação atual no backend, como o load.
    setSelecionado: (state: LojaState, { payload }: PayloadAction<string>) => ({...state, idLojaSelecionada: payload}),
    },
  },
)

export const lojaActions = lojaSlice.actions

export default lojaSlice.reducer
