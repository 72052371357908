import React from 'react'
import { useSelector } from 'react-redux'

import Avatar from 'components/avatar/Avatar'
import IconButton from 'components/iconButton/IconButton'
import { ModalConfirmDataLoss } from 'components/modals/ModalConfirmDataLoss'
import { ModalNoPermission } from 'components/modals/ModalNoPermission'
import UserDrawer from 'containers/userDrawer/UserDrawer'
import useDrawer from 'hooks/useDrawer'
import { useHandleNavigate } from 'hooks/useHandleNavigate'
import useWindowDimensions from 'hooks/useWindowDimensions'
import ChangeIcon from 'icons/change'
import Grid from 'icons/grid'
import HomeIcon from 'icons/home'
import Menu from 'icons/menu'
import LojaSelector from 'store/loja/selector'
import UserSelector from 'store/user/selector'
import logo from 'styles/assets/logo_81.png'
import breakpoints from 'styles/breakpoints'
import colors from 'styles/colors'
// import { DefaultProps } from 'styles/types'

import ShortCutMenu from './components/shortcutMenu/ShortcutMenu'
import { IconsDivider, IconsWrapper, LogoWrapper, TopbarWrapper, TopbarContainer, TextDivider } from './styles'

export type TopbarProps = {
  id: string
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const Topbar = ({ id, onClick }: TopbarProps) => {
  const userName = useSelector(UserSelector.name)
  const lojaSelecionada = useSelector(LojaSelector.lojaSelecionada)
  const { width } = useWindowDimensions()
  const { openDrawer: openUserDrawer } = useDrawer('user-drawer')
  const { openDrawer: openShortcutDrawer } = useDrawer('shortcut-drawer')
  const navigate = useHandleNavigate()

  return (
    <>
      <TopbarContainer>
        <TopbarWrapper id={id}>
          {width < breakpoints.medium && (
            <IconButton id="menu-icon" tooltipTitle="Menu" onClick={onClick}>
              <Menu />
            </IconButton>
          )}
          <LogoWrapper>
            <img src={logo} alt="Tenda de Hórus logo" height={'30'} />
            {width >= breakpoints.medium && (
              <IconButton id="home-icon" tooltipTitle="Início" onClick={() => navigate('/')}>
                <HomeIcon />
              </IconButton>
            )}
          </LogoWrapper>
          {width >= breakpoints.medium ? (
            <IconsWrapper>              
                <TextDivider>
                  <p > Você esta na loja: {lojaSelecionada ? lojaSelecionada?.nomeLoja : ''}</p>  
                </TextDivider>
              <IconsDivider />
              <IconButton
                id="change-icon"
                tooltipTitle="Trocar de loja"
                onClick={() => navigate('/loja-favorita/trocar')}
              >
                <ChangeIcon color={colors.white} />
              </IconButton>
              <IconsDivider />
              
              <div onClick={openUserDrawer} style={{ cursor: 'pointer' }}>
                <Avatar id="user-avatar" userName={userName} tooltipTitle="Perfil do usuário" />
              </div>
            </IconsWrapper>
          ) : (
            <IconButton id="grid-icon" tooltipTitle="Atalhos" onClick={openShortcutDrawer}>
              <Grid />
            </IconButton>
          )}
          <ShortCutMenu id="shortcut-drawer" />
          <UserDrawer id={'user-drawer'} />
        </TopbarWrapper>
      </TopbarContainer>

      <ModalConfirmDataLoss />
      <ModalNoPermission />
    </>
  )
}

export default Topbar
