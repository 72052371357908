import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { store } from 'store'

export function requestInterceptor(request: AxiosRequestConfig) {
  const accessToken = store.getState()?.user?.dsToken
  
  if (accessToken && request.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }

  return request
}

export function responseInterceptor(response: AxiosResponse) {
  if (response.data?.data) {
    return response.data
  }
  return response
}
