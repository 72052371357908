/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode } from 'react'

import withTooltip from 'hoc/withTooltip'
import styled from 'styled-components'
import colors from 'styles/colors'
import { DefaultProps } from 'styles/types'

import { PropTypes } from '@material-ui/core'
import MUIButton from '@material-ui/core/Button'

type ButtonProps = {
  type?: 'button' | 'submit'
  disabled?: boolean
  fullWidth?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  color?: PropTypes.Color
  bgColor?: keyof typeof colors
  startIcon?: ReactElement
  endIcon?: ReactElement
  onClick?: any 
  href?: string
  children?: ReactNode
} & DefaultProps

const CustomButton = styled(MUIButton)<{ bgColor?: keyof typeof colors }>`
  background-color: ${props => colors[props.bgColor || 'primary']} !important;
`

const Button = ({
  id,
  disabled,
  fullWidth,
  children,
  type = 'button',
  variant = 'contained',
  startIcon = <></>,
  endIcon = <></>,
  color = 'primary',
  bgColor = 'primary',
  onClick,
  href,
  ...rest
}: ButtonProps): ReactElement => (
  <CustomButton
    id={id}
    color={color}
    bgColor={bgColor}
    variant={variant}
    startIcon={startIcon}
    endIcon={endIcon}
    disabled={disabled}
    type={type}
    fullWidth={fullWidth}
    onClick={onClick}
    href={href} 
    {...rest}
  >
    {children}
  </CustomButton>
)

export default withTooltip(Button)
